import React, { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router';
import store from 'store2';

import { Spinner } from '@peakon/bedrock/react/spinner';
import { useSearchParams } from '@peakon/shared/hooks/useSearchParams';
import { errorReporter } from '@peakon/shared/utils/errorReporter';

import { resetContexts } from '../../actions/ContextActions';
import { resetScores } from '../../actions/HeatmapActions';
import { onSetClosesAt } from '../../actions/SettingsActions';
import { useAppDispatch } from '../../utils/reduxHooks';

import styles from './ProxyViewTransfer.css';

export const ProxyViewTransfer = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo');

  useEffect(() => {
    transfer().catch((error) => {
      errorReporter.error(error);
    });

    async function transfer() {
      if (store.session.get('peakon.ui.closesAt') && !redirectTo) {
        dispatch(onSetClosesAt(null));
      }

      dispatch(resetContexts());
      dispatch(resetScores());

      await queryClient.invalidateQueries(['session']);
      history.push(redirectTo ?? '/');
    }
  }, [dispatch, history, queryClient, redirectTo]);

  return (
    <div className={styles.root}>
      <Spinner size="32" />
    </div>
  );
};
