import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import { segment } from '@peakon/shared/schemas/api/benchmarks';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { Percentile } from '../../../PercentileSelector/PercentileSelector';
import { BenchmarkType } from '../SegmentBenchmarkForm';

const SEGMENT_PARAMS = {
  include: 'attribute,benchmark,benchmark.attribute',
  fields: {
    segments: '+,benchmark',
  },
};

type UpdateSegmentParams = {
  segmentId: string;
  data: {
    type: 'segments';
    attributes: {
      benchmarkType?: BenchmarkType;
      benchmarkSelector?: {
        id?: string;
        percentile?: Percentile;
        sector?: string;
      };
      benchmarkPropagate?: boolean;
    };
  };
};

const parsedResponseSchema = z.object({
  data: segment,
  included: z.array(z.object({}).passthrough()).optional(),
});

const updateSegment = async ({ segmentId, data }: UpdateSegmentParams) => {
  const response = await api.patch(`/segments/${segmentId}`, SEGMENT_PARAMS, {
    data,
  });

  return validateData(jsonapiparser(response), parsedResponseSchema, {
    errorMessagePrefix: 'getSegments-responseSchema',
  });
};

export const useUpdateSegmentMutation = () => {
  return useMutation({
    mutationFn: updateSegment,
  });
};
