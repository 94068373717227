import React, { Component, Fragment, forwardRef } from 'react';

import classnames from 'classnames';
import throttle from 'lodash/throttle';
import ReactResizeDetector from 'react-resize-detector';
import {
  matchPath,
  useHistory,
  useLocation,
  NavLink,
  NavLinkProps,
} from 'react-router-dom';

import {
  ScoresFlagsStrengthDiscIcon as StrengthIcon,
  ScoresFlagsWarningDiscIcon as PriorityIcon,
  ScoresFlagsPriorityMcpDiscIcon as CompanyPriorityIcon,
  McpRejectedTinyIcon as RejectedIcon,
} from '@peakon/bedrock/icons/graphic';
import {
  NavigationBasicNavigationArrowLeftIcon as BackIcon,
  NavigationBasicNavigationChevronRightThickIcon as ChevronRight,
} from '@peakon/bedrock/icons/system';
import { Heading3 } from '@peakon/bedrock/react/typography';
import { Ellipsis, Portal, Select } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import OverlayContext from './OverlayContext';

import styles from './styles.css';

type BackProps = {
  href: string;
  testId?: string;
  children?: React.ReactNode;
};

const Back = ({ href, children, testId }: BackProps) => (
  <React.Fragment>
    <NavLink
      exact
      className={styles.backContent}
      to={href}
      data-test-id={testId}
    >
      <BackIcon className={styles.backIcon} />
      {children}
    </NavLink>
  </React.Fragment>
);

type BackButtonProps = {
  children: React.ReactNode;
} & ({ href: string; to?: never } | { href?: never; to: NavLinkProps['to'] });

const BackButton = ({ children, href, ...other }: BackButtonProps) => {
  return (
    <Fragment>
      <NavLink
        exact
        className={styles.backButton}
        data-test-id="page-header-back"
        // @ts-expect-error - Type 'string | undefined' is not assignable to type 'LocationDescriptor<unknown> | ((location: Location<unknown>) => LocationDescriptor<unknown>)'.
        to={href}
        {...other}
      >
        {children}
      </NavLink>
      <ChevronRight aria-hidden="true" className={styles.backButtonIcon} />
    </Fragment>
  );
};

type BreadcrumbsProps = {
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  iconClassName?: string;
  priorityStatus?: string;
  isStrength?: boolean;
  title?: React.ReactNode;
  wrapperClassName?: string;
};

const Breadcrumbs = ({
  children,
  className,
  iconClassName,
  icon,
  priorityStatus,
  isStrength,
  title,
  wrapperClassName,
}: BreadcrumbsProps) => (
  <nav
    aria-label={t('breadcrumb_navigation_a11y_label')}
    className={classnames(styles.breadcrumbs, className)}
    data-test-id="breadcrumbs"
  >
    {isStrength ? (
      <StrengthIcon
        className={styles.criticalIcon}
        data-test-id="strength-icon"
      />
    ) : (
      <Fragment>
        {priorityStatus === 'suggested' && (
          <PriorityIcon
            className={styles.criticalIcon}
            data-test-id="priority-icon"
          />
        )}
        {priorityStatus === 'accepted' && (
          <CompanyPriorityIcon
            className={styles.criticalIcon}
            data-test-id="manual-priority-icon"
          />
        )}
        {priorityStatus === 'rejected' && (
          <RejectedIcon
            className={styles.criticalIcon}
            data-test-id="rejected-priority-icon"
          />
        )}
      </Fragment>
    )}
    {icon &&
      // @ts-expect-error No overload matches this call.
      React.cloneElement(icon, {
        className: classnames(styles.breadcrumbsIcon, iconClassName),
      })}
    <div className={styles.breadcrumbsWrapper}>
      <div className={classnames(styles.breadcrumbsContent, wrapperClassName)}>
        {children}
      </div>
      {title}
    </div>
  </nav>
);

type TitleProps = {
  children: React.ReactNode;
  ellipsis?: boolean;
};

const Title = ({ children, ellipsis = true }: TitleProps) => (
  <Heading3 level={1} id="page-header-title" data-test-id="page-header-title">
    {ellipsis ? <Ellipsis>{children}</Ellipsis> : children}
  </Heading3>
);

type NavProps = {
  children: React.ReactNode;
  className?: string;
};

const Nav = ({ children, className }: NavProps) => (
  <div className={classnames(styles.nav, className)}>{children}</div>
);

type SubNavProps = {
  children: React.ReactNode;
  className?: string;
};

const SubNav = ({ children, className }: SubNavProps) => (
  <div className={classnames(styles.subnav, className)}>{children}</div>
);

type ActionsProps = {
  children: React.ReactNode;
  className?: string;
};

const Actions = ({ children, className }: ActionsProps) => (
  <div className={classnames(styles.actions, className)}>{children}</div>
);

type HeaderTabsProps = {
  'aria-label'?: string;
  children?: React.ReactNode;
  className?: string;
  testId?: string;
  tabClassName?: string;
  tabIndex?: number;
  ariaLabelledBy?: string;
};

const HeaderTabs = forwardRef<HTMLDivElement, HeaderTabsProps>(
  (
    {
      tabIndex = 0,
      'aria-label': ariaLabel,
      children,
      className,
      tabClassName,
      testId,
      ariaLabelledBy,
    },
    ref,
  ) => (
    <div className={classnames(styles.tabs, className)} data-test-id={testId}>
      {/*  eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */}
      <nav
        ref={ref}
        className={styles.tabsNav}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        role="tablist"
      >
        {/* eslint-enable */}
        {React.Children.map(
          children,
          (child: $TSFixMe) =>
            child &&
            React.cloneElement(child, {
              role: 'tab',
              tabIndex,
              activeClassName: styles.navItemActive,
              className: classnames(
                className,
                styles.navItem,
                tabClassName,
                child.props.className,
              ),
            }),
        )}
      </nav>
    </div>
  ),
);

HeaderTabs.displayName = 'HeaderTabs';

type MobileTabsProps = {
  className?: string;
  options: Array<{ path: string }>;
};

const MobileTabs = ({ className, options = [] }: MobileTabsProps) => {
  const history = useHistory();
  const location = useLocation();

  const match =
    options.find((option) => {
      return matchPath(location.pathname, {
        path: option.path,
        exact: false,
        strict: false,
      });
    }) || options[0];

  return (
    <div className={classnames(styles.mobileTabs, className)}>
      <Select
        isClearable={false}
        onChange={(
          // @ts-expect-error - Parameter 'option' implicitly has an 'any' type.
          option,
        ) => history.replace(option.path)}
        options={options}
        value={match}
      />
    </div>
  );
};

type FiltersProps = {
  actionsOnly?: boolean;
  children?: React.ReactNode;
  mobile?: boolean;
  className?: string;
  showMobile?: boolean;
};

const Filters = ({
  actionsOnly,
  className,
  children,
  mobile,
  showMobile,
}: FiltersProps) => (
  <div
    className={classnames(styles.filters, className, {
      [styles.actionsOnly]: actionsOnly,
      [styles.mobile]: mobile,
      [styles.showMobile]: showMobile,
    })}
  >
    {children}
  </div>
);

type FiltersWrapProps = {
  children?: React.ReactNode;
};

const FiltersWrap = ({ children }: FiltersWrapProps) => (
  <div className={styles.wrap}>{children}</div>
);

Filters.Wrap = FiltersWrap;

type FiltersSectionProps = {
  children?: React.ReactNode;
  className?: string;
  main?: boolean;
};

const FiltersSection = ({ children, main, className }: FiltersSectionProps) => (
  <div
    className={classnames(
      styles.section,
      {
        [styles.main]: main,
      },
      className,
    )}
  >
    {children}
  </div>
);

Filters.Section = FiltersSection;

type FilterProps = {
  children?: React.ReactNode;
  testId?: string;
  id?: string;
};

const Filter = ({ children, testId, ...other }: FilterProps) => (
  <div className={styles.filter} data-test-id={testId} {...other}>
    {children}
  </div>
);

type PageHeaderProps = {
  isLoading?: boolean;
  modifier?: string;
  children: React.ReactNode;
};

type PageHeaderState = $TSFixMe;

class PageHeader extends Component<PageHeaderProps, PageHeaderState> {
  static Actions = Actions;
  static Back = Back;
  static BackButton = BackButton;

  static Breadcrumbs = Breadcrumbs;
  static Filter = Filter;
  static Filters = Filters;
  static Nav = Nav;
  static SubNav = SubNav;
  static Tabs = HeaderTabs;
  static MobileTabs = MobileTabs;
  static Title = Title;

  appNode: $TSFixMe;
  overlayContext: $TSFixMe;
  rootElem: $TSFixMe;

  constructor(props: PageHeaderProps) {
    super(props);

    this.appNode = null;
    this.rootElem = React.createRef();
    this.onResize = throttle(this.onResize, 300);

    this.overlayContext = {
      onShowOverlay: this.onShowOverlay.bind(this),
      onHideOverlay: this.onHideOverlay.bind(this),
    };

    this.state = {
      filters: [],
    };
  }

  componentWillUnmount() {
    const appNode = this.getAppNode();

    if (appNode) {
      appNode.classList.remove('no-overflow');
    }
  }

  getAppNode() {
    if (!this.appNode) {
      const nodes = window.document.getElementsByClassName('app-content');

      if (!nodes) {
        return;
      }

      this.appNode = nodes[0];
    }

    return this.appNode || window.document.body;
  }

  onShowOverlay(id: $TSFixMe) {
    const appNode = this.getAppNode();

    if (appNode) {
      appNode.classList.add('no-overflow');
    }

    this.setState(
      (
        // @ts-expect-error no implicit any
        prev,
      ) => ({
        filters: prev.filters.concat(id),
      }),
    );
  }

  onHideOverlay(id: $TSFixMe) {
    const appNode = this.getAppNode();

    if (appNode) {
      appNode.classList.remove('no-overflow');
    }

    this.setState(
      (
        // @ts-expect-error no implicit any
        prev,
      ) => ({
        filters: prev.filters.filter(
          (
            // @ts-expect-error no implicit any
            filter,
          ) => filter !== id,
        ),
      }),
    );
  }

  onResize = () => {
    this.forceUpdate();
  };

  renderOverlay() {
    const measures = this.rootElem.current.getBoundingClientRect();

    return (
      <Portal>
        <ReactResizeDetector
          handleHeight
          handleWidth
          targetDomEl={this.rootElem}
          onResize={this.onResize}
        />
        <div
          style={{
            top: measures.top + measures.height,
            left: measures.left,
          }}
          className={styles.overlay}
        />
      </Portal>
    );
  }

  render() {
    const { children, isLoading, modifier } = this.props;
    const { filters } = this.state;

    const hasOverlay = filters.length > 0;

    return (
      <OverlayContext.Provider value={this.overlayContext}>
        <div
          ref={this.rootElem}
          id="page-header-root"
          className={classnames(styles.container, {
            [styles.isLoading]: isLoading,
            // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
            [styles[modifier]]: modifier,
          })}
        >
          {children}
        </div>
        {hasOverlay && this.renderOverlay()}
      </OverlayContext.Provider>
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default PageHeader;
