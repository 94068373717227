import { useInfiniteQuery } from '@tanstack/react-query';
import { z } from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import { segment } from '@peakon/shared/schemas/api/benchmarks';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { queryKeys } from './queryKeys';
import { catchHandler } from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

const URL_PARAMS = {
  include: 'attribute,benchmark,benchmark.attribute',
  fields: {
    segments: '+,benchmark',
  },
  per_page: 50,
  filter: {
    benchmarkType: 'null$ne',
  },
};

type GetParams = {
  nextPageLink?: string;
};

const parsedResponseSchema = z.object({
  data: z.array(segment),
  included: z.array(z.object({}).passthrough()).optional(),
  links: z.object({
    first: z.string(),
    next: z.string().optional(),
    self: z.string(),
  }),
  meta: z.object({
    page: z.object({
      total: z.number(),
    }),
  }),
});

const getSegments = async ({ nextPageLink }: GetParams) => {
  const URL: [string, typeof URL_PARAMS | null] = nextPageLink
    ? [nextPageLink, null]
    : ['/segments', URL_PARAMS];

  const response = await api.get(...URL);
  return validateData(jsonapiparser(response), parsedResponseSchema, {
    errorMessagePrefix: 'getSegments-responseSchema',
  });
};

export const useGetSegmentsInfiniteQuery = () => {
  const dispatch = useAppDispatch();
  const { data, ...rest } = useInfiniteQuery({
    queryKey: queryKeys.segments(),
    queryFn: ({ pageParam }) => getSegments({ nextPageLink: pageParam }),
    onError: (error) => dispatch(catchHandler(error)),
    getNextPageParam: (previousData) => previousData?.links?.next,
  });

  return {
    data,
    segments: data?.pages?.flatMap((page) => page.data) ?? [],
    ...rest,
  };
};
