import ErrorReporter from '@peakon/error-reporter';

import env from './env';

export const errorReporter = new ErrorReporter({
  accessToken: env.rollbar ? env.rollbar.client : '',
  environment: env.clusterEnv,
  version: env.version,
});

export type ErrorReporterType = typeof errorReporter;
