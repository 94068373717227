import { useMutation } from '@tanstack/react-query';

import api from '@peakon/shared/utils/api';

type CreateKeywordParams = {
  sensitiveConceptId: string;
  sensitiveKeyword: { keyword: string; locale: string };
};

const createKeyword = async ({
  sensitiveKeyword,
  sensitiveConceptId,
}: CreateKeywordParams) =>
  api.post(`/sensitive/concepts/${sensitiveConceptId}/keywords`, null, {
    data: {
      type: 'sensitive_keywords',
      attributes: sensitiveKeyword,
    },
  });

export const useCreateKeywordMutation = () => {
  return useMutation({
    mutationFn: createKeyword,
  });
};
