import { t } from '../../../../../features/i18next/t';
import { ProductName } from '../types';
import { isActive, getPath } from '../utils';

const profile: ProductName = 'profile';

export const getProfileMenu = ({
  isConsultant,
  isProxyViewing,
  features,
}: {
  isConsultant: boolean;
  isProxyViewing: boolean;
  features: Array<string>;
}) => {
  return {
    name: profile,
    menuLabel: t('multi_product__profile'),
    children: [
      {
        menuLabel: t('multi_product__profile'),
        name: profile,
        children: [
          {
            name: 'editProfile',
            menuLabel: t('multi_product__profile__menu_item__edit_profile'),
            getPath: getPath({
              path: '/account',
            }),
            get shouldRender() {
              return !isConsultant && !isProxyViewing;
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'emailNotifications',
            menuLabel: features.includes('notificationPreferencesByChannel')
              ? t('multi_product__profile__menu_item__user_notifications')
              : t('multi_product__profile__menu_item_email_notifications'),
            getPath: getPath({
              path: '/account/subscriptions',
            }),
            get shouldRender() {
              return !isConsultant && !isProxyViewing;
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'changePassword',
            menuLabel: t('multi_product__profile__menu_item__change_password'),
            getPath: getPath({
              path: '/account/change-password',
            }),
            get shouldRender() {
              return !isConsultant && !isProxyViewing;
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
          {
            name: 'security',
            menuLabel: t('multi_product__profile__menu_item__security'),
            getPath: getPath({
              path: '/account/security',
            }),
            get shouldRender() {
              return !isConsultant && !isProxyViewing;
            },
            get isActive() {
              return isActive({
                matchingPaths: [this.getPath({ includeParams: false })],
              });
            },
          },
        ],
      },
    ],
  };
};
