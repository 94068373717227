export const SENSITIVE_COMMENT_CONCEPT_TYPES = [
  'danger',
  'health',
  'discrimination',
  'ethics',
] as const;

export const SENSITIVE_COMMENT_CONCEPT_NOTIFICATIONS = [
  'instant',
  'digest',
] as const;

export const SENSITIVE_COMMENT_CONCEPT_STATUS = ['active', 'inactive'] as const;
