import env from './env';
import { TranslatedString } from '../features/i18next/t';

const PAGE_TITLE_SUFFIX = 'Workday Peakon Employee Voice';

const getPageTitle = (title?: TranslatedString) => {
  let pageTitle = title ? `${title} - ${PAGE_TITLE_SUFFIX}` : PAGE_TITLE_SUFFIX;

  if (env.version && env.displayAppVersionEnabled) {
    pageTitle = `${env.version.substring(0, 7)} - ${pageTitle}`;
    if (env.clusterEnv) {
      pageTitle = `${pageTitle} (${env.clusterEnv})`;
    }
  }

  return pageTitle;
};

// eslint-disable-next-line import/no-default-export
export default getPageTitle;
