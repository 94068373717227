import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import store from 'store2';

import jsonapiparser from '@peakon/jsonapiparser';
import { Context } from '@peakon/records';
import api from '@peakon/shared/utils/api';
import { errorReporter } from '@peakon/shared/utils/errorReporter';

import { fetchCategories } from './CategoryActions';
import { QUESTION_FIELDS } from './QuestionResultsActions';
import {
  getCriticalSegmentsForContext,
  getSegmentLinks,
} from './SegmentActions';
import {
  mainCategoryGroup,
  scoreGroups,
} from '../selectors/CategoryGroupSelectors';
import { getScoreMode } from '../selectors/CompanySelectors';
import { currentContext } from '../selectors/ContextSelectors';
import { getColumnStates } from '../selectors/heatmap';
import { getDatasetParams } from '../selectors/SessionSelectors';
import { Dispatch, GetState } from '../types/redux';
import { asyncDispatch } from '../utils';

export const getColumns =
  (group: $TSFixMe, { attrition }: $TSFixMe = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const enabledGroups = scoreGroups(state);

    const resource = attrition
      ? 'HEATMAP_ATTRITION_COLUMN_LIST'
      : 'HEATMAP_COLUMN_LIST';

    // prevent fetching all categories. There might be a situation where
    // the group has not been fetched yet after coming back from the card
    // view
    if (!group || !enabledGroups.has(group)) {
      // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
      group = mainCategoryGroup(state);

      if (group === null) {
        return dispatch({
          type: `${resource}_FAILED`,
        });
      }
    }

    const hasQuestionScores = !attrition && group !== 'values';
    const context = currentContext(state);
    const datasetParams = {
      ...getDatasetParams(state)(QUESTION_FIELDS),
      includeSubcategories: true,
    };

    const includeReadMoreUrl = !['values', 'other'].includes(group); // FIXME: This should be taken care of on the API side

    const categoryFields = ['+', 'name', 'parentCategory'];

    if (includeReadMoreUrl) {
      categoryFields.push('readMoreUrl');
    }

    if (group === 'values') {
      categoryFields.push('value');
    }

    return asyncDispatch({
      dispatch,
      resource,
      data: { group },
      action: Promise.all([
        fetchCategories(context, {
          'filter[group]': group,
          fields: {
            categories: categoryFields.join(','),
            values: 'id',
          },

          include:
            group === 'values' ? 'parentCategory,value' : 'parentCategory',
        }),
        hasQuestionScores
          ? api
              .get(
                `/scores/contexts/${context.id}/questions/group/${group}`,
                datasetParams,
              )
              .then(jsonapiparser)
          : Promise.resolve({ data: [] }),
      ]).then(([categories, questionScores]) => {
        return {
          // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
          ...categories,
          questionScores,
          driverMode: state.company.driverMode,
        };
      }),
    });
  };

export function getSessionKey(state: $TSFixMe) {
  const context = currentContext(state);

  if (!context) {
    return null;
  }

  let accessKey = `peakon.ui.compare.heatmap.${context.id}`;

  accessKey += `.v2`;

  return accessKey;
}

function getStoredState(state: $TSFixMe) {
  const accessKey = getSessionKey(state);

  let data;

  try {
    data = store.session.get(accessKey);
  } catch {
    // noop
  }

  return data;
}

export const close = () => ({
  type: 'HEATMAP_CLOSE',
});

export const closeAttrition = () => ({
  type: 'HEATMAP_CLOSE_ATTRITION',
});

export const onChangeContext =
  (group: $TSFixMe) => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    // clear the current scores
    dispatch(resetScores());

    // attempt to load previously stored segments for
    // the new context
    const data = getStoredState(state);

    if (!data) {
      return Promise.all([
        group && dispatch(getColumns(group)),
        dispatch(getContextScore()),
        dispatch(
          getCriticalSegmentsForContext(
            { group, isInitialLoad: true },
            {
              interval: 'year',
            },
          ),
        ),
      ]);
    }

    return Promise.all([
      group && dispatch(getColumns(group)),
      dispatch({
        type: 'HEATMAP_REVIVE_SEGMENTS',
        data,
      }),
    ]).then(() => {
      return dispatch(getContextScore());
    });
  };

export const bootstrapAttrition =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();

    const { filters, rows } = state.heatmap;
    const data = getStoredState(state);

    // @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
    if (filters.booting && data && !isEmpty(data, 'rows')) {
      return Promise.all([
        dispatch({
          type: 'HEATMAP_ATTRITION',
          data,
        }),

        dispatch(getColumns('engagement', { attrition: true })),
      ]);
    } else if (!rows.isEmpty()) {
      return Promise.all([
        dispatch({
          type: 'HEATMAP_ATTRITION',
        }),

        dispatch(getColumns('engagement', { attrition: true })),
      ]);
    }

    return Promise.all([
      dispatch({
        type: 'HEATMAP_ATTRITION',
      }),

      dispatch(getColumns('engagement', { attrition: true })),
      dispatch(
        getCriticalSegmentsForContext(
          {
            group: 'engagement',
            isInitialLoad: !data || (data && isEmpty(data.rows)),
          },
          {
            interval: 'year',
          },
        ),
      ),
    ]);
  };

export const bootstrap = () => (dispatch: Dispatch, getState: GetState) => {
  const state = getState();
  const group = state.heatmap.filters.group || mainCategoryGroup(state);

  dispatch(resetScores());

  const data = getStoredState(state);

  // when loading for the first time, with no stored state
  if (!data || (data && isEmpty(data.rows))) {
    return Promise.all([
      dispatch(getColumns(group)),
      dispatch(getContextScore()),
      dispatch(
        getCriticalSegmentsForContext(
          { group, isInitialLoad: true },
          {
            interval: 'year',
          },
        ),
      ),
    ]);
  }

  return Promise.all([
    dispatch(getColumns(data.filters.group)),
    dispatch({
      type: 'HEATMAP_REVIVE',
      data,
    }),
  ]).then(() => {
    return dispatch(getContextScore());
  });
};

export const loadScores =
  (
    segment: $TSFixMe,
    {
      participation,
      attrition,
      group,
      round,
      interval = 'year',
    }: $TSFixMe = {},
  ) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { features } = state;
    const hasUseScoreParticipationCategoryGroups = features.includes(
      'useScoreParticipationCategoryGroups',
    );

    const { expanded, withQuestions } = getColumnStates(state);

    if (state.heatmap.scores.has(segment.id)) {
      const scores = state.heatmap.scores.get(segment.id);

      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'PropertyNam... Remove this comment to see the full error message
      if (participation && has(scores, [null, 'participation'])) {
        return dispatch({
          type: 'HEATMAP_SEGMENT_CACHE_HIT',
          data: { segmentId: segment.id },
        });
      }
    }

    const dashboardContext = currentContext(state);
    const datasetParams = getDatasetParams(state);
    const hasQuestionScores = withQuestions && !attrition && group !== 'values';

    const params = datasetParams({
      interval,
      attrition,
      participation,
      participationRound: participation,
    });

    if (round !== null) {
      // @ts-expect-error Property 'changes' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      params.changes = round;
    }

    if (attrition) {
      return asyncDispatch({
        dispatch,
        resource: 'HEATMAP_SEGMENT',
        data: { segmentId: segment.id, attrition },
        action: getAttrition({
          contextId: dashboardContext.id,
          segmentId: segment.id,
          params,
          interval,
          hasUseScoreParticipationCategoryGroups,
        }).then((categoryScores) => ({
          categoryScores,
        })),
      });
    } else if (segment instanceof Context) {
      // @ts-expect-error Property 'includeSubcategories' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      params.includeSubcategories = true;
      // @ts-expect-error Property 'fields' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      params.fields = {
        score_segments: 'categoryScores,size',
        category_scores: 'scores,changes',
      };

      // @ts-expect-error Property 'include' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      params.include = 'categoryScores';

      // @ts-expect-error Property 'changes' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      if (params.changes) {
        // @ts-expect-error Property 'fields' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
        params.fields = merge(params.fields, {
          'score_groups[category_scores]': 'scores,changes',
        });
      }

      if (hasUseScoreParticipationCategoryGroups) {
        // @ts-expect-error Property 'fields' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
        params.fields.score_groups = '+,globalParticipation';
      }

      return asyncDispatch({
        dispatch,
        resource: 'HEATMAP_SEGMENT_CONTEXT',
        data: { segmentId: segment.id, group },
        action: Promise.all([
          api
            .get(`/scores/contexts/${segment.id}/v2/group/${group}`, params)
            .then(jsonapiparser),
          hasQuestionScores
            ? api
                .get(
                  `/scores/contexts/${dashboardContext.id}/questions/group/${group}`,
                  {
                    ...params,
                    ...QUESTION_FIELDS,
                    includeSubcategories: true,
                  },
                )
                .then(jsonapiparser)
            : Promise.resolve({ data: [] }),
        ]).then(([categoryScores, questionScores]) => {
          return {
            categoryScores,
            questionScores,
            driverMode: state.company.driverMode,
          };
        }),
        isActionCancelled: () => {
          const { heatmap } = getState();
          return heatmap.filters.group !== group;
        },
      }).then(() => {
        return dispatch({
          type: 'HEATMAP_SCORES_CHECK_IF_EMPTY',
          data: { scores: getState().heatmap.scores },
        });
      });
    }

    return asyncDispatch({
      dispatch,
      resource: 'HEATMAP_SEGMENT',
      data: { segmentId: segment.id, group },
      action: Promise.all([
        getScores({
          contextId: dashboardContext.id,
          segmentId: segment.id,
          params,
          group,
          scores: state.heatmap.scores.get(segment.id),
          hasUseScoreParticipationCategoryGroups,
        }),
        hasQuestionScores && expanded
          ? api
              .get(
                `/scores/contexts/${dashboardContext.id}/questions/group/${group}`,
                {
                  ...params,
                  'filter[employee.segmentIds]': `${segment.id}$contains`,
                  ...QUESTION_FIELDS,
                  includeSubcategories: true,
                },
              )
              .then(jsonapiparser)
          : Promise.resolve({ data: [] }),
      ]).then(([categoryScores, questionScores]) => {
        return {
          categoryScores,
          questionScores,
          driverMode: state.company.driverMode,
        };
      }),
      isActionCancelled: () => {
        const { heatmap } = getState();
        return heatmap.filters.group !== group;
      },
    });
  };

export const loadQuestionScores =
  ({ group, round, interval = 'year' }: $TSFixMe = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const { heatmap } = state;
    const dashboardContext = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const params = datasetParams({
      interval,
    });

    if (round !== null) {
      // @ts-expect-error Property 'changes' does not exist on type '{ interval: any; attrition: any; participation: any; participationRound: any; } & AdditionalDatasetParams'.ts(2339)
      params.changes = round;
    }

    const segmentIds = heatmap.rows
      // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
      .filter((row) => {
        let hasScores = false;
        if (state.heatmap.scores.has(row.id)) {
          hasScores = Boolean(state.heatmap.scores.get(row.id));
        }

        return hasScores && !row.isContext() && !row.hasLoadedQuestionScores;
      })
      // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
      .map((row) => row.id);

    return Promise.all(
      // @ts-expect-error TS(7006): Parameter 'segmentId' implicitly has an 'any' type... Remove this comment to see the full error message
      segmentIds.map((segmentId) => {
        return asyncDispatch({
          dispatch,
          resource: 'HEATMAP_SEGMENT_QUESTION_SCORES',
          data: { segmentId },
          action: api
            .get(
              `/scores/contexts/${dashboardContext.id}/questions/group/${group}`,
              {
                ...params,
                'filter[employee.segmentIds]': `${segmentId}$contains`,
                ...QUESTION_FIELDS,
                includeSubcategories: true,
              },
            )
            .then(jsonapiparser),
        });
      }),
    );
  };

const getAttrition = ({
  contextId,
  segmentId,
  params,
  interval,
  hasUseScoreParticipationCategoryGroups,
}: $TSFixMe) => {
  const queryParams = {
    ...params,
    attrition: true,
    attritionInfo: true,
    benchmark: true,
    interval,
    observations: false,
    participation: true,
    participationRound: true,
    subdrivers: true,
    fields: {
      categories: 'id',
      engagement_drivers: '+,category',
      engagement_segments: [
        'attrition',
        'classification',
        'drivers',
        'participation',
        'scores',
        'size',
      ].join(','),
    },

    include: 'drivers,drivers.category',
  };

  if (hasUseScoreParticipationCategoryGroups) {
    queryParams.fields.engagement_segments += ',globalParticipation';
  }

  return api
    .get(`/engagement/contexts/${contextId}/segments/${segmentId}`, queryParams)
    .then(jsonapiparser);
};

const getScores = ({
  contextId,
  segmentId,
  params,
  group,
  scores,
  hasUseScoreParticipationCategoryGroups,
}: $TSFixMe) => {
  const queryParams = {
    includeSubcategories: true,
    fields: {
      score_segments: 'categoryScores,scores,size',
      category_scores: 'scores',
    },

    include: 'categoryScores',
    ...params,
  };

  if (params.participation) {
    // make sure to only fetch scores if they aren't loaded yet
    queryParams.scores = !scores;
    queryParams.fields.score_segments += ',participation';
  } else {
    queryParams.scores = true;
  }

  if (params.changes) {
    queryParams.fields.category_scores = 'scores,changes';
  }

  if (hasUseScoreParticipationCategoryGroups) {
    queryParams.fields.score_segments += ',globalParticipation';
  }

  return api
    .get(
      `/scores/contexts/${contextId}/group/${group}/segments/${segmentId}`,
      queryParams,
    )
    .then(jsonapiparser);
};

export const getContextScore =
  () => (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const context = currentContext(state);

    return dispatch({
      type: 'HEATMAP_CONTEXT_SCORE_ADD',
      data: { context },
    });
  };

export const onContextScoreChange =
  (value: $TSFixMe) => (dispatch: Dispatch) => {
    if (!value) {
      return dispatch({
        type: 'HEATMAP_CONTEXT_SCORE_REMOVE',
      });
    }

    return dispatch(getContextScore());
  };

export const onExportHeatmap =
  ({ includeLinks }: $TSFixMe) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);
    const scoreMode = getScoreMode(state);

    const { participation, expanded } = getColumnStates(state);

    const { heatmap } = state;

    const data = {
      segmentIds: heatmap.rows
        // @ts-expect-error TS(7006): Parameter 'row' implicitly has an 'any' type.
        .filter((row) => row.isSegment() && row.isTopLevel())
        // @ts-expect-error TS(7006): Parameter 'segment' implicitly has an 'any' type.
        .map((segment) => segment.id)
        .toJS(),
      display: heatmap.filters.mode,
      engagementScore: scoreMode === 'nps' ? 'enps' : 'average',
      includeLinks,
      includeContext: true, // export is only available for heatmap
      includeSubcategories: expanded,
    };

    if (participation) {
      // @ts-expect-error Property 'participation' does not exist on type '{ segmentIds: any; display: any; engagementScore: string; includeLinks: any; includeContext: boolean; includeSubcategories: any; }'.ts(2339)
      data.participation = true;
    }

    if (heatmap.filters.mode === 'diffToCustomRound') {
      // @ts-expect-error Property 'customChange' does not exist on type '{ segmentIds: any; display: any; engagementScore: string; includeLinks: any; includeContext: boolean; includeSubcategories: any; }'.ts(2339)
      data.customChange = heatmap.filters.round;
    }

    const params = datasetParams({
      interval: heatmap.filters.interval,
    });

    return asyncDispatch({
      dispatch,
      resource: 'EXPORT_HEATMAP',
      action: api.post(
        `/scores/contexts/${context.id}/group/${heatmap.filters.group}/segments/export`,
        params,
        data,
      ),
    });
  };

export const onToggleLinks =
  (rowIndex: string, { interval = 'year' } = {}) =>
  (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const context = currentContext(state);
    const datasetParams = getDatasetParams(state);

    const row = state.heatmap.rows.get(rowIndex);

    if (!row) {
      // trying to debug these low-occurence issues
      // https://jira2.workday.com/browse/PEAKONISSUE-6151
      // https://jira2.workday.com/browse/PEAKONISSUE-6112
      errorReporter.error(new Error(`row is undefined:`), {
        rows: state.heatmap.rows.toJS(),
        rowIndex,
      });
    }

    if (row.expanded) {
      return dispatch({
        type: 'HEATMAP_SEGMENT_COLLAPSE',
        data: { rowIndex },
      });
    }

    return asyncDispatch({
      dispatch,
      resource: 'HEATMAP_SEGMENT_EXPAND',
      data: { rowIndex, segmentId: row.id },
      action: getSegmentLinks(
        context.id,
        row.id,
        datasetParams({
          include: 'critical,segment,segment.attribute',
          interval,
          categoryGroup: state?.heatmap?.filters?.group,
        }),
      ),
    });
  };

const RELOAD_FILTERS = ['group', 'round'];

export const onChangeFilter =
  (filter: string, value: string, columnId: string) =>
  (dispatch: Dispatch, getState: GetState) => {
    const { filters: prevFilters } = getState().heatmap;

    let reload = false;

    if (
      RELOAD_FILTERS.includes(filter) ||
      // we only need to look at the previous value, because
      // when transitioning to `diffToCustomRound`, we'll change
      // the round quickly after, and data will be reloaded then
      (filter === 'mode' && prevFilters.mode === 'diffToCustomRound')
    ) {
      reload = true;
    }

    return dispatch({
      type: 'HEATMAP_FILTERS_UPDATED',
      data: {
        filter,
        value,
        reload,
        columnId,
      },
    });
  };

export const onCollapseAll = () => ({
  type: 'HEATMAP_COLUMNS_COLLAPSE',
});

export const onExpandAll = () => ({
  type: 'HEATMAP_COLUMNS_EXPAND',
});

export const onCollapseColumn = (categoryId: string) => ({
  type: 'HEATMAP_COLUMN_COLLAPSE',
  data: categoryId,
});

export const onExpandColumn = (categoryId: string) => ({
  type: 'HEATMAP_COLUMN_EXPAND',
  data: categoryId,
});

export const onRemoveColumn = (categoryId: string) => ({
  type: 'HEATMAP_COLUMN_REMOVE',
  data: categoryId,
});

export const onResetFilters = () => ({
  type: 'HEATMAP_FILTERS_RESET',
});

export const resetScores = () => ({
  type: 'HEATMAP_CACHE_CLEAR',
});

export const resetColumns = () => {
  return {
    type: 'HEATMAP_COLUMNS_RESET',
  };
};

export const onAddSegments = (segments: $TSFixMe) => ({
  type: 'HEATMAP_SEGMENTS_ADD',
  data: segments,
});

export const onClearSegments = () => ({
  type: 'HEATMAP_SEGMENTS_CLEAR',
});

export const onSetSegments = (segments: $TSFixMe) => ({
  type: 'HEATMAP_SEGMENTS_SET',
  data: segments,
});
