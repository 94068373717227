import React, { Fragment, useEffect, useState } from 'react';

import {
  NavigationFocusAreasIcon,
  NavigationInsightTopicsIcon,
} from '@peakon/bedrock/icons/system';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { Stack, Inline, Box } from '@peakon/bedrock/react/layout';
import {
  Heading2,
  Heading3,
  Paragraph,
} from '@peakon/bedrock/react/typography';
import Segment from '@peakon/records/SegmentRecord';
import { trackSegmentRecalculationRateLimit } from '@peakon/shared/features/analytics/customAnalyticsEvents';
import { t } from '@peakon/shared/features/i18next/t';

import { RecalculationModal } from './components/RecalculationModal';
import {
  RecalculationType,
  usePostRecalculation,
} from './queries/usePostRecalculation';
import {
  showErrorNotification,
  showSuccessNotification,
} from '../../../../actions/NotificationActions';
import { ExternalLink, SegmentsPickerInput } from '../../../../components';
import { useAppDispatch } from '../../../../utils/reduxHooks';

import styles from './Recalculation.css';

export const Recalculation = () => {
  const SUPPORT_LINK =
    'https://doc.workday.com/peakon/en-us/workday-peakon-employee-voice/insights/manager-dashboards/recalculate-dashboards.html';

  const dispatch = useAppDispatch();

  const [focusAreasSegment, setFocusAreasSegment] = useState<Segment>();
  const [topicsSegment, setTopicsSegment] = useState<Segment>();
  const [showRecalculationModal, setShowRecalculationModal] = useState(false);
  const [recalculationType, setRecalculationType] =
    useState<RecalculationType>();

  // Modal ref is used to know when the modal exit animation is finished and the modal is unmounted,
  // so we can clear the segment selection if the call was successful
  const [modalRef, setModalRef] = useState<HTMLDialogElement | null>(null);
  const [shouldClearSelection, setShouldClearSelection] = useState(false);

  const modalRefCallback = (node: HTMLDialogElement | null) => {
    setModalRef(node);
  };

  useEffect(() => {
    if (!modalRef && shouldClearSelection) {
      recalculationType === 'priorities'
        ? setFocusAreasSegment(undefined)
        : setTopicsSegment(undefined);

      setShouldClearSelection(false);
    }
  }, [modalRef, recalculationType, shouldClearSelection]);

  const handleClickRecalculate = (type: RecalculationType) => {
    setRecalculationType(type);
    setShowRecalculationModal(true);
  };

  const handleCloseModal = () => {
    setShowRecalculationModal(false);
  };

  const { mutate: recalculate } = usePostRecalculation({
    onSuccess: () => {
      handleCloseModal();
      setShouldClearSelection(true);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('data_settings__recalculation__success'),
        }),
      );
    },
    onError: (error, variables) => {
      if (error.code === 429) {
        trackSegmentRecalculationRateLimit(variables.recalculationType);
      }
      handleCloseModal();
      const actualErrorMessage =
        error.code === 422
          ? {
              title: t('notifications__error'),
              message: t('data_settings__recalculation__failure'),
            }
          : error;

      dispatch(showErrorNotification(actualErrorMessage));
    },
  });

  return (
    <Fragment>
      <Heading2 level={2}>{t('data_settings__recalculation__title')}</Heading2>

      <div className={styles.description}>
        <Paragraph>
          {t('data_settings__recalculation__description__v2', {
            components: {
              support_article: (
                <ExternalLink href={SUPPORT_LINK} target="_blank" />
              ),
            },
          })}
        </Paragraph>
      </div>

      <Card>
        <div className={styles.cardInner}>
          <div className={styles.cardTextContainer}>
            <Inline spacing={8} noWrap>
              <div className={styles.icon}>
                <NavigationFocusAreasIcon />
              </div>
              <Stack spacing={16}>
                <Heading3 level={3}>
                  {t('data_settings__recalculation__focus_areas')}
                </Heading3>
                <Paragraph>
                  {t(
                    'data_settings__recalculation__focus_areas__description__v2',
                  )}
                </Paragraph>
              </Stack>
            </Inline>
          </div>

          <div className={styles.pickerContainer}>
            <Box padding={16}>
              <Stack spacing={32}>
                <SegmentsPickerInput
                  onRemove={() => setFocusAreasSegment(undefined)}
                  onSelect={([segment]) => setFocusAreasSegment(segment)}
                  placeholder={t('data_settings__recalculation__selection')}
                  selected={focusAreasSegment ? [focusAreasSegment] : []}
                  single
                  testId="focus-areas-segment-picker"
                />
                <Button
                  variant="primary"
                  disabled={!focusAreasSegment}
                  onClick={() => handleClickRecalculate('priorities')}
                >
                  {t(
                    'data_settings__recalculation__focus_areas__recalculate__v2',
                  )}
                </Button>
              </Stack>
            </Box>
          </div>
        </div>
      </Card>

      <div className={styles.cardSeparator} />

      <Card>
        <div className={styles.cardInner}>
          <div className={styles.cardTextContainer}>
            <Inline spacing={8} noWrap>
              <div className={styles.icon}>
                <NavigationInsightTopicsIcon />
              </div>
              <Stack spacing={16}>
                <Heading3 level={3}>
                  {t('data_settings__recalculation__topics')}
                </Heading3>
                <Paragraph>
                  {t('data_settings__recalculation__topics__description__v2')}
                </Paragraph>
              </Stack>
            </Inline>
          </div>

          <div className={styles.pickerContainer}>
            <Box padding={16}>
              <Stack spacing={32}>
                <SegmentsPickerInput
                  onRemove={() => setTopicsSegment(undefined)}
                  onSelect={([segment]) => setTopicsSegment(segment)}
                  placeholder={t('data_settings__recalculation__selection')}
                  selected={topicsSegment ? [topicsSegment] : []}
                  single
                  testId="topics-segment-picker"
                />
                <Button
                  variant="primary"
                  disabled={!topicsSegment}
                  onClick={() => handleClickRecalculate('topics')}
                >
                  {t('data_settings__recalculation__topics__recalculate__v2')}
                </Button>
              </Stack>
            </Box>
          </div>
        </div>
      </Card>

      <RecalculationModal
        modalRefCallback={modalRefCallback}
        recalculationType={recalculationType}
        showRecalculationModal={showRecalculationModal}
        handleCloseModal={handleCloseModal}
        focusAreasSegment={focusAreasSegment}
        topicsSegment={topicsSegment}
        recalculate={recalculate}
      />
    </Fragment>
  );
};
