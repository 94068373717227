import { Map } from 'immutable';
import get from 'lodash/get';
import map from 'lodash/map';
import merge from 'lodash/merge';
import sortBy from 'lodash/sortBy';
import { z } from 'zod';

import { getEmployeeScopedLocaleInfo } from '@peakon/shared/features/i18next/helpers';
import { validateLocaleId } from '@peakon/shared/features/i18next/localesValidation';
import { getTranslatedLocaleNamesMap } from '@peakon/shared/features/i18next/translatedLocaleNames';

import AttributeRecord from './AttributeRecord';
import SegmentRecord from './SegmentRecord';
import TranslationRecord from './TranslationRecord';
import { reportZodError } from '../../shared/src/utils/reportZodError';

function parser(item: $TSFixMe) {
  if (!item) {
    return null;
  }

  switch (item.type) {
    case 'attributes': {
      return AttributeRecord.createFromApi(item);
    }
    case 'segments': {
      return SegmentRecord.createFromApi(item);
    }
    default: {
      return null;
    }
  }
}

export function getRelationships(data: $TSFixMe) {
  if (!data) {
    return {};
  }

  const keys = Object.keys(data);
  const parsed = {};

  keys.forEach((key) => {
    const item = data[key];

    if (Array.isArray(item)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      parsed[key] = item.map(parser);
    } else if (item) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      parsed[key] = parser(item);
    }
  });

  return parsed;
}

export function sortRanges(ranges: $TSFixMe, standard: $TSFixMe) {
  let sortedRanges = sortBy(
    ranges

      .filter(
        (
          // @ts-expect-error no implicit any
          range,
        ) => range.attributes,
      )

      .map(
        (
          // @ts-expect-error no implicit any
          range,
        ) => ({
          id: range.id,

          from:
            range.attributes.from !== null
              ? parseFloat(range.attributes.from)
              : null,

          to:
            range.attributes.to !== null
              ? parseFloat(range.attributes.to)
              : null,
        }),
      ),
    'to',
  );

  if (standard === 'separation_date') {
    sortedRanges = sortedRanges.reverse().map((range) => {
      const { id, from: prevFrom, to: prevTo } = range;

      return {
        id,
        from: prevTo === null ? null : -prevTo,
        to: prevFrom === null ? null : -prevFrom,
      };
    });
  }

  return sortedRanges;
}

export function sortTranslations(translations: $TSFixMe) {
  if (!translations) {
    return Map();
  }

  const sortedTranslations = sortBy(
    map(translations, (translation, locale) => {
      return {
        language: getTranslatedLocaleNamesMap().get(
          validateLocaleId(getEmployeeScopedLocaleInfo(locale).id),
        ),
        locale,
        translation,
      };
    }),
    'language',
  );

  return Map(
    sortedTranslations.map(({ language, locale, translation }) => [
      locale,
      new TranslationRecord({
        language,
        locale,
        translation,
      }),
    ]),
  );
}

const getAttribute = (attribute = {}) => ({
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  id: attribute.id,
  // @ts-expect-error TS(2339): Property 'attributes' does not exist on type '{}'.
  ...attribute.attributes,
});

const criticalFromJsonApi = (critical: $TSFixMe) => {
  if (!critical) {
    return {};
  }

  return {
    id: get(critical, 'id'),
    context: get(critical, 'relationships.context', {}),
  };
};

const priorityFromJsonApi = (priority: $TSFixMe) => {
  if (!priority) {
    return {};
  }

  return {
    id: get(priority, 'id'),
    context: get(priority, 'relationships.context', {}),
    setByEmployee: get(priority, 'relationships.setByEmployee', undefined),
  };
};

export const fromJsonApi = (segment: $TSFixMe, { strict = true } = {}) => {
  const data =
    segment.type === 'segments'
      ? segment
      : get(segment, 'relationships.segment');

  const {
    id,
    attributes: {
      abbreviation,
      contextId,
      direct,
      employeeCount,
      logo,
      name,
      nameTranslated,
      type,
      ...otherAttributes
    },
  } = data;

  const classification =
    get(segment, 'attributes.classification') ||
    get(segment, 'relationships.critical.attributes.classification');

  const critical = get(segment, 'relationships.critical', {});
  const priority = get(segment, 'relationships.priority', {});

  const parsed = {
    id,
    abbreviation,
    attribute: getAttribute(get(data, 'relationships.attribute')),
    classification,
    contextId,
    direct,
    employeeCount,
    logo,
    name,
    nameTranslated,
    source: get(segment, 'attributes.source'),
    type,
    critical: criticalFromJsonApi(critical),
    priority: priorityFromJsonApi(priority),
  };

  if (!strict) {
    return { ...otherAttributes, ...segment.attributes, ...parsed };
  }

  return parsed;
};

export const ensureJavascriptObject = (input: unknown) => {
  let data = normalize(input);

  if (data === input) {
    // make a copy to make sure we don't mutate the original object
    data = { ...data };
  }

  // handle cases where we pass a plain object but with Immutable types as properties
  Object.keys(data).forEach((key) => {
    data[key] = normalize(data[key]);
  });

  return data;
};

const normalize = (data: unknown) => {
  return typeof data === 'object' &&
    data !== null &&
    data !== undefined &&
    'toJS' in data &&
    typeof data.toJS === 'function'
    ? data.toJS()
    : data;
};

/** calls `.strict()` recursively on schemas but still respecting `.passthrough()` as a way of opting out
 *
 * so we should use `.passthrough()` on referenced Records that we don't want to validate
 */
function makeStrictRecursive(schema: z.Schema): z.Schema {
  // @ts-expect-error our type is not accurate
  if (schema._def.unknownKeys === 'passthrough') {
    return schema;
  }
  // https://github.com/colinhacks/zod/issues/2062
  if (schema instanceof z.ZodObject) {
    const newObject: Record<string, z.Schema> = {};
    for (const key in schema.shape) {
      if (schema.shape[key]._def.unknownKeys === 'passthrough') {
        newObject[key] = schema.shape[key];
        continue;
      }
      newObject[key] = makeStrictRecursive(schema.shape[key]);
    }
    return z.object(newObject).strict();
  }
  if (schema instanceof z.ZodArray) {
    return z.array(makeStrictRecursive(schema.element));
  }
  if (schema instanceof z.ZodOptional) {
    return z.optional(makeStrictRecursive(schema.unwrap()));
  }
  if (schema instanceof z.ZodNullable) {
    return z.nullable(makeStrictRecursive(schema.unwrap()));
  }
  if (schema instanceof z.ZodTuple) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return z.tuple(schema.items.map((item: any) => makeStrictRecursive(item)));
  }
  return schema;
}

type LogLevel = 'info' | 'error' | 'warning';

type LogOptions =
  | {
      environment: 'local';
      logLevel: Extract<LogLevel, 'error'>;
    }
  | {
      environment: 'test';
      logLevel: LogLevel;
    }
  | {
      environment: 'production';
      logLevel: LogLevel;
    }
  | {
      environment: 'staging';
      logLevel: LogLevel;
    };

/**
 * Checks whether the input is valid according to the Record's testing schema.
 *
 * Does nothing by default unless we enable it by passing the environments on which it should run,
 * or by forcing all errors to be show by setting `FORCE_SHOW_RECORD_SCHEMA_ERRORS` to `true` in the `.env` file.
 *
 * It modifies the given schema to make it strict and will error out when the input contains properties which are not described in the schema.
 *
 * This is only meant to be used in order to refine the proper Record schemas, as it allows us to progressively enable validation on different environments.
 * Once we are certain that the schema/properties on the schema are correct, we should use `validateRecord` instead.
 *
 * @param input - The input to be parsed.
 * @param schema - The schema to be used for parsing.
 * @param options - Additional options for parsing.
 * @param options.errorMessagePrefix - A prefix which we will use to make the errors coming from the different schemas easier to find.
 * @param options.log - The different log levels and environments to use for ErrorReporter (optional, defaults to `error`)
 * @param options.environments - Environments to run on (optional).
 */
export const validateTestingSchema = (
  input: unknown,
  schema: z.Schema,
  options: {
    defaultValues?: Record<string, unknown>;
    errorMessagePrefix: string;
    log?: Array<LogOptions>;
  },
) => {
  const isLocal = !ENV.clusterEnv;

  const environmentLogOption = options.log?.find((log) => {
    return isLocal
      ? log.environment === 'local'
      : log.environment === ENV.clusterEnv;
  });

  const logLevel = ENV.forceShowRecordSchemaErrors
    ? 'error'
    : environmentLogOption?.logLevel;

  if (!logLevel) {
    return;
  }

  const normalizedDefaultValues = ensureJavascriptObject(options.defaultValues);
  const normalized = ensureJavascriptObject(input);
  const parsed = makeStrictRecursive(schema).safeParse(
    merge(normalizedDefaultValues, normalized),
  );

  if (parsed.success) {
    return;
  }

  reportZodError({
    error: parsed.error,
    errorMessagePrefix: options.errorMessagePrefix,
    data: normalized,
    logLevel,
  });
};
