import React, { lazy, Suspense, useEffect } from 'react';

import { Route, Switch } from 'react-router-dom';
import store from 'store2';

import { analytics } from '@peakon/shared/features/analytics/analytics';
// eslint-disable-next-line no-restricted-imports
import { useTrackPageViews } from '@peakon/shared/features/analytics/useTrackPageViews';
import { ProxyViewProvider } from '@peakon/shared/providers/ProxyViewProvider';

import CheckRedirectToSubdomain from './CheckRedirectToSubdomain/CheckRedirectToSubdomain';
import * as FeatureActions from '../../actions/FeatureActions';
import ErrorBoundary from '../../components/ErrorBoundary';
import { getAnalyticsEnrichmentData } from '../../selectors/SessionSelectors';
import { useAppDispatch, useAppSelector } from '../../utils/reduxHooks';
import { Routes } from '../AppRoutes/Routes';
import ErrorScreen from '../ErrorScreen';
import { ProxyViewTransfer } from '../ProxyViewTransfer/ProxyViewTransfer';
import { StandaloneSuggestionPage } from '../StandaloneSuggestionPage/StandaloneSuggestionPage';
import SubdomainTransfer from '../Subdomain/SubdomainTransfer';

// eslint-disable-next-line import/order -- can probably move import freely, just being cautious
import 'bootstrap-4-grid';

const AppChrome = lazy(() => import('../AppChrome'));
const ConfirmAccount = lazy(() => import('../ConfirmAccount/ConfirmAccount'));
const LoginRoutes = lazy(() =>
  import('../login/LoginRoutes').then((m) => ({
    default: m.LoginRoutes,
  })),
);
const NoSurveys = lazy(() => import('../NoSurveys/NoSurveys'));
const SamlAssert = lazy(() => import('../SamlAssert/SamlAssert'));
const StaticRoutes = lazy(() =>
  import('../static/StaticRoutes').then((m) => ({ default: m.StaticRoutes })),
);
const Unsubscribe = lazy(() => import('../Unsubscribe'));
const StandaloneConversationPage = lazy(
  () => import('../StandaloneConversationPage/StandaloneConversationPage'),
);
const SurveyTransfer = lazy(
  () => import('../Schedules/transfers/SurveyTransfer'),
);
const EmployeeSurveyTransfer = lazy(
  () => import('../Schedules/transfers/EmployeeSurveyTransfer'),
);
const OAuthSubdomainChecker = lazy(
  () => import('../OAuthSubdomainChecker/OAuthSubdomainChecker'),
);
const SurveySsoFlow = lazy(() => import('../SurveySsoFlow/SurveySsoFlow'));

const AppRoutes = () => (
  <ProxyViewProvider>
    <AppChrome>
      <Routes />
    </AppChrome>
  </ProxyViewProvider>
);

const App = () => {
  useTrackPageViews();
  const dispatch = useAppDispatch();

  const colorMode = useAppSelector((state) => state.account?.colorMode);

  const analyticsEnrichmentData = useAppSelector(getAnalyticsEnrichmentData);

  const isAuthenticated = useAppSelector(
    (state) => state.session.isAuthenticated,
  );

  useEffect(() => {
    if (analyticsEnrichmentData) {
      analytics.identifyUser(analyticsEnrichmentData);
    }
  }, [analyticsEnrichmentData]);

  useEffect(() => {
    // remove deprecated localStorage keys
    store.remove('peakon.cookieConsent.functional');
    store.remove('peakon.cookieConsent.analytical');
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(FeatureActions.featuresRead());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (colorMode) {
      window.document
        .querySelector('html')
        ?.setAttribute('data-theme', colorMode);
    } else {
      window.document.querySelector('html')?.removeAttribute('data-theme');
    }
  }, [colorMode]);

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <CheckRedirectToSubdomain>
          <Switch>
            <Route
              path="/:provider(slack|microsoft-teams|bamboohr)/complete"
              component={OAuthSubdomainChecker}
            />
            <Route
              path="/signup/confirm/:token"
              exact
              component={ConfirmAccount}
            />
            <Route path="/login" component={LoginRoutes} />
            <Route path="/unsubscribe/:token" component={Unsubscribe} />
            <Route path="/surveys/no-surveys" component={NoSurveys} />
            <Route path="/saml/:companyId/assert" component={SamlAssert} />
            <Route
              path="/conversations/reply/:token"
              component={StandaloneConversationPage}
            />
            <Route
              path="/team_suggestions/:token"
              component={StandaloneSuggestionPage}
            />
            <Route
              path={['/what-is-peakon', '/kiosk-export']}
              component={StaticRoutes}
            />
            <Route
              path="/transfer/preview/survey/question/:questionId"
              component={SurveyTransfer}
              exact
            />
            <Route
              path="/transfer/preview/survey"
              component={SurveyTransfer}
              exact
            />
            <Route
              path="/transfer/subdomain/:transferToken"
              component={SubdomainTransfer}
              exact
            />
            <Route
              path="/transfer/employee/survey/:responseId"
              component={EmployeeSurveyTransfer}
              exact
            />
            <Route
              path="/transfer/proxy-view"
              component={ProxyViewTransfer}
              exact
            />
            <Route path="/employee-voice" component={SurveySsoFlow} exact />
            <Route
              path={[
                '/account',
                '/courses',
                '/improve',
                '/admin',
                '/actions',
                '/dashboard',
                '/metrics',
                '/slack',
                '/microsoft-teams',
                '/bamboohr',
                '/workday',
                '/transfer',
                '/oauth',
              ]}
            >
              <AppRoutes />
            </Route>
            {/* This needs to be separated from the above declaration so we can have 'exact'
              and render 404 without the shell */}
            <Route path="/" exact>
              <AppRoutes />
            </Route>
            <Route component={ErrorScreen} />
          </Switch>
        </CheckRedirectToSubdomain>
      </Suspense>
    </ErrorBoundary>
  );
};

// eslint-disable-next-line import/no-default-export
export default App;
