import React, { useEffect } from 'react';

import { Link as ReactRouterLink } from 'react-router-dom';
import { z } from 'zod';

import { EmployeeDashboardSpreadsheetImportedIcon as EmployeesImportIcon } from '@peakon/bedrock/icons/graphic';
import { LinkButton } from '@peakon/bedrock/react/button';
import { Inline } from '@peakon/bedrock/react/layout';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { usePrevious } from '../../../../hooks/usePrevious';
import { ProgressBar } from '../../TaskNotification/ProgressBar/ProgressBar';
import { TaskNotification } from '../../TaskNotification/TaskNotification';
import { Task } from '../types';

import styles from './styles.css';

const completedDataSchema = z.object({
  status: z.literal('completed'),
  result: z.object({
    created: z.number(),
    updated: z.number(),
    skipped: z.number(),
  }),
  logs: z.array(
    z.object({
      severity: z.enum(['warning', 'info']),
      message: z.string(),
      position: z.string().optional(),
    }),
  ),
  logFileUrl: z.string(),
});

const errorDataSchema = z.object({
  status: z.literal('error'),
  error: z.string(),
});

const inProgressDataSchema = z.object({
  status: z.literal('inProgress'),
  percent: z.number().min(0).max(100),
});

const dataSchema = z.union([
  inProgressDataSchema,
  completedDataSchema,
  errorDataSchema,
]);

const typeSchema = z.literal('employeesImportFile');

const employeesImportTask = z.object({
  id: z.string(),
  type: typeSchema,
  data: dataSchema,
  onComplete: z.function(
    z.tuple([completedDataSchema]).rest(z.never()),
    z.void(),
  ),
});

export type EmployeesImportTask = z.infer<typeof employeesImportTask>;

export type EmployeesImportTaskInitializationData = Pick<
  EmployeesImportTask,
  'type' | 'onComplete'
>;

export const isEmployeesImportTask = (
  task: Task,
): task is EmployeesImportTask => {
  if (typeSchema.safeParse(task.type).success) {
    validateData(task, employeesImportTask, {
      errorMessagePrefix: 'EmployeeImportBackgroundTask',
    });
    return true;
  }
  return false;
};

export const EmployeesImportTaskNotification = ({
  task,
  onClose,
}: {
  task: EmployeesImportTask;
  onClose: () => void;
}) => {
  const previousStatus = usePrevious(task.data.status);
  useEffect(() => {
    if (
      previousStatus !== task.data.status &&
      task.data.status === 'completed'
    ) {
      task.onComplete(task.data);
    }
  }, [previousStatus, task]);

  if (task.data.status === 'inProgress') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t('background_task__employee_import_file__importing__title')}
        onClose={onClose}
        status="inProgress"
      >
        <TaskNotification.Content icon={EmployeesImportIcon} iconSize="large">
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__employee_import_file__importing__text')}
            </Typography.P>
          </div>
          <ProgressBar percent={task.data.percent} />
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  if (task.data.status === 'completed') {
    return (
      <TaskNotification
        taskId={task.id}
        heading={t('background_task__employee_import_file__ready__title')}
        onClose={onClose}
        status="completed"
      >
        <TaskNotification.Content icon={EmployeesImportIcon} iconSize="large">
          <div className={styles.description}>
            <Typography.P>
              {t('background_task__employee_import_file__ready__text', {
                replace: {
                  created: task.data.result.created,
                  updated: task.data.result.updated,
                  skipped: task.data.result.skipped,
                },
              })}
              &nbsp;
              {task.data.logs.length > 0 &&
                t('employees__import__result-warnings', {
                  replace: { count: task.data.logs.length },
                })}
            </Typography.P>
          </div>
          <Inline spacing={8}>
            <LinkButton
              as={ReactRouterLink}
              variant="primary"
              to="/admin/employees/import/completed"
            >
              {t('background_task__employee_import_file__ready__details')}
            </LinkButton>
            <LinkButton variant="tertiary" href={task.data.logFileUrl}>
              {t('employee_import__download_log')}
            </LinkButton>
          </Inline>
        </TaskNotification.Content>
      </TaskNotification>
    );
  }

  return (
    <TaskNotification
      taskId={task.id}
      heading={t('background_task__employee_import_file__error__title')}
      onClose={onClose}
      status="error"
    >
      <TaskNotification.Content icon={EmployeesImportIcon} iconSize="large">
        <Typography.P className={styles.error}>{task.data.error}</Typography.P>
      </TaskNotification.Content>
    </TaskNotification>
  );
};
