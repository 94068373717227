import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import { updatedConceptSchema } from '../schemas';
import { SensitiveCommentConceptNotifications } from '../SensitiveCommentsConcept';

const responseSchema = z.object({
  data: updatedConceptSchema,
});

type QueryParams = {
  onSuccess: (val: $TSFixMe) => void;
};

type UpdateNotificationsParams = {
  conceptId: string;
  attributes: {
    notifications: SensitiveCommentConceptNotifications;
  };
};

const updateNotifications = async ({
  attributes,
  conceptId,
}: UpdateNotificationsParams) => {
  const response = await api.patch(`/sensitive/concepts/${conceptId}`, null, {
    data: {
      type: 'sensitive_concepts',
      id: conceptId,
      attributes,
    },
  });
  return validateData(response, responseSchema, {
    errorMessagePrefix: 'updateNotifications-responseSchema',
  });
};

export const useUpdateNotificationsMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: updateNotifications,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: (response) => {
      onSuccess(response);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t(
            'engagement__settings__sensitive_comments_custom_concept_updated',
          ),
        }),
      );
    },
  });
};
