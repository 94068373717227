import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

import jsonapiparser from '@peakon/jsonapiparser';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import { queryKeys } from './queryKeys';
import { catchHandler } from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import { sensitiveConceptSchema } from '../schemas';

const responseDataSchema = z.array(sensitiveConceptSchema);

const URL_PARAMS = {
  include: 'keywords',
  fields: {
    concepts: '+',
    keywords: 'keyword,locale',
  },
};

const fetchConcepts = async () => {
  const response = await api.get('/sensitive/concepts', URL_PARAMS);
  // @ts-expect-error Object is of type 'unknown'.ts(2571)
  const responseData = jsonapiparser(response).data;
  return validateData(responseData, responseDataSchema, {
    errorMessagePrefix: 'fetchConcepts-responseDataSchema',
  });
};

export const useSensitiveConceptsQuery = (enabled: boolean) => {
  const dispatch = useAppDispatch();

  return useQuery({
    queryKey: queryKeys.concepts(),
    onError: (error) => dispatch(catchHandler(error)),
    queryFn: fetchConcepts,
    enabled,
  });
};
