import React, { useState } from 'react';

import { InView } from 'react-intersection-observer';

import { EmptyStateNoData } from '@peakon/bedrock/illustrations/react/empty-state';
import { Button } from '@peakon/bedrock/react/button';
import { Card } from '@peakon/bedrock/react/card';
import { Stack } from '@peakon/bedrock/react/layout';
import { Spinner } from '@peakon/bedrock/react/spinner';
import { Heading3, Paragraph } from '@peakon/bedrock/react/typography';
import { Typography } from '@peakon/components';
import { t } from '@peakon/shared/features/i18next/t';

import {
  ConfirmationModal,
  ConfirmationModalProps,
  ConfirmationModalType,
} from './ConfirmationModal';
import { SharedDashboardCard } from './SharedDashboardCard';
import { PaywallModal } from '../../../../components/PaywallModal/PaywallModal';
import { useAddon } from '../../../../hooks/useAddon';
import { useRightsChecker } from '../../../../hooks/useRightsChecker';
import { useDeleteAllSharedMutation } from '../queries/useDeleteAllSharedMutation';
import { useDeleteSharedMutation } from '../queries/useDeleteSharedMutation';
import { useMarkAllPrivateMutation } from '../queries/useMarkAllPrivateMutation';
import useSharedDashboards from '../queries/useSharedDashboards';
import { useDataSettingsRedirect } from '../utils/useDataSettingsRedirect';

import styles from './styles.css';

export const SharingSettings = () => {
  useDataSettingsRedirect();
  const [hasSharePublicAdminRight] = useRightsChecker(['sharePublic:admin']);
  const hasCollaborationShareableAddOn = useAddon('collaboration_shareable');

  const [confirmationModalSettings, setConfirmationModalSettings] =
    useState<ConfirmationModalProps>({ isVisible: false });

  const handleOnClosePaywallModal = (): void => {
    window.location.href = '/dashboard';
  };

  const hideConfirmationModal = (): void => {
    setConfirmationModalSettings({
      isVisible: false,
    });
  };

  const { mutate: revokeDashboard } = useDeleteSharedMutation({
    onSuccess: hideConfirmationModal,
  });
  const { mutate: markAllPrivate } = useMarkAllPrivateMutation({
    onSuccess: hideConfirmationModal,
  });
  const { mutate: revokeAll } = useDeleteAllSharedMutation({
    onSuccess: hideConfirmationModal,
  });

  const {
    data: shares,
    isLoading,
    fetchNextPage: loadMore,
    hasNextPage: hasMore,
    totalCount,
    refetch,
    isFetched,
  } = useSharedDashboards();

  return (
    <React.Fragment>
      {!hasCollaborationShareableAddOn && (
        <PaywallModal
          fetchAddOn
          feature="collaboration_shareable"
          isOpen
          onClose={handleOnClosePaywallModal}
          ctaLabel={t('paywall__return')}
        />
      )}

      {!isLoading && shares?.length > 0 && (
        <div className={styles.header} data-test-id="sharing-content">
          <Typography type="h3" className={styles.title}>
            <React.Fragment>
              {shares && (
                <span className={styles.titleText}>
                  {t('share_settings__title', {
                    replace: { count: totalCount },
                  })}
                </span>
              )}
              <Button
                variant="primary"
                onClick={() =>
                  setConfirmationModalSettings({
                    isVisible: true,
                    modalType: ConfirmationModalType.RevokeAll,
                    onConfirm: revokeAll,
                    onCancel: hideConfirmationModal,
                  })
                }
                size="small"
              >
                {t('share_settings__revoke_all')}
              </Button>
              <Button
                variant="primary"
                onClick={() =>
                  setConfirmationModalSettings({
                    isVisible: true,
                    modalType: ConfirmationModalType.MakeAllPrivate,
                    onConfirm: markAllPrivate,
                    onCancel: hideConfirmationModal,
                  })
                }
                size="small"
              >
                {t('share_settings__mark_all_private')}
              </Button>
            </React.Fragment>
          </Typography>

          <div>{t('share_settings__description')}</div>
        </div>
      )}

      {isLoading && !isFetched && (
        <div className={styles.loader}>
          <Spinner />
        </div>
      )}

      {!isLoading && shares?.length === 0 && (
        <div data-test-id="sharing-empty-state">
          <Stack spacing={16} alignInline="center">
            <EmptyStateNoData />
            <Heading3 level={2}>
              {t('share_settings__title', {
                replace: { count: 0 },
              })}
            </Heading3>
            <Paragraph>{t('share_settings__empty__info1')}</Paragraph>
            <Paragraph>{t('share_settings__empty__info2')}</Paragraph>
          </Stack>
        </div>
      )}
      {shares?.length > 0 && (
        <div className={styles.list}>
          <Card>
            {shares.map((item, index) => (
              <SharedDashboardCard
                key={index}
                canSetToPublic={Boolean(hasSharePublicAdminRight)}
                shareContext={item.relationships.context}
                shareAttributes={item.attributes}
                toggleIsPublic={refetch}
                revokeDashboard={(context) => {
                  setConfirmationModalSettings({
                    isVisible: true,
                    modalType: ConfirmationModalType.Revoke,
                    name: context.attributes.name,
                    onConfirm: () => {
                      revokeDashboard(context.id);
                    },
                    onCancel: hideConfirmationModal,
                  });
                }}
              />
            ))}
          </Card>
        </div>
      )}

      {hasMore && <InView onChange={(inView) => inView && loadMore()} />}

      <ConfirmationModal
        isVisible={confirmationModalSettings.isVisible}
        modalType={confirmationModalSettings.modalType}
        name={confirmationModalSettings.name}
        onConfirm={confirmationModalSettings.onConfirm}
        onCancel={confirmationModalSettings.onCancel}
        testId="shared-dashboard-confirmation-modal"
      />
    </React.Fragment>
  );
};
