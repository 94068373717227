import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';
import { validateData } from '@peakon/shared/utils/validateData/validateData';

import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';
import { updatedConceptSchema } from '../schemas';

const responseSchema = z.object({
  data: updatedConceptSchema,
});

type QueryParams = {
  onSuccess: (name: string) => void;
};

type UpdateConceptParams = {
  id: string;
  name: string;
};

const updateConcept = async (concept: UpdateConceptParams) => {
  const response = await api.patch(`/sensitive/concepts/${concept.id}`, null, {
    data: {
      type: 'sensitive_concepts',
      id: concept.id,
      attributes: { name: concept.name },
    },
  });
  return validateData(response, responseSchema, {
    errorMessagePrefix: 'updateConcept-responseSchema',
  });
};

export const useUpdateConceptMutation = ({ onSuccess }: QueryParams) => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: updateConcept,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: ({ data: { attributes } }) => {
      onSuccess(attributes.name);
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t(
            'engagement__settings__sensitive_comments_custom_concept_updated',
          ),
        }),
      );
    },
  });
};
