import { useMutation } from '@tanstack/react-query';

import { t } from '@peakon/shared/features/i18next/t';
import api from '@peakon/shared/utils/api';

import { SensitiveConceptKeyword } from './../SensitiveCommentsConcept';
import {
  catchHandler,
  showSuccessNotification,
} from '../../../../../actions/NotificationActions';
import { useAppDispatch } from '../../../../../utils/reduxHooks';

const deleteKeyword = async (keyword: SensitiveConceptKeyword): Promise<void> =>
  // @ts-expect-error Type 'unknown' is not assignable to type 'void'.ts(2322)
  api.delete(`/sensitive/keywords/${keyword.id}`, null);

export const useDeleteKeywordMutation = () => {
  const dispatch = useAppDispatch();
  return useMutation({
    mutationFn: deleteKeyword,
    onError: (error) => dispatch(catchHandler(error)),
    onSuccess: () =>
      dispatch(
        showSuccessNotification({
          title: t('notifications__success'),
          message: t('engagement__settings__sensitive_comments_custom_deleted'),
        }),
      ),
  });
};
