import React from 'react';

import { DriverIcon } from '@peakon/bedrock/icons/driver';
import { Stack, Inline } from '@peakon/bedrock/react/layout';
import { Paragraph } from '@peakon/bedrock/react/typography';

import styles from './styles.css';

type Props = {
  group?: string;
  categoryStandard?: string;
  categoryName?: string;
  description?: string;
  parentCategoryStandard?: string;
};

export function TeamSuggestionCardCategory({
  group,
  categoryStandard,
  categoryName,
  description,
  parentCategoryStandard,
}: Props) {
  return (
    <Inline spacing={16} noWrap>
      <DriverIcon
        group={group}
        category={parentCategoryStandard || categoryStandard}
        className={styles.categoryIcon}
      />
      <Stack spacing={8}>
        <Paragraph bold size="large">
          {categoryName}
        </Paragraph>
        <Paragraph>{description}</Paragraph>
      </Stack>
    </Inline>
  );
}
