import get from 'lodash/get';

import Account from '@peakon/records/AccountRecord';

const account = (
  state: Account | null = null,
  action: $TSFixMe,
): Account | null => {
  switch (action.type) {
    case 'SESSION_RESTORED':
    case 'SESSION_READ_SUCCESS': {
      const relationshipAccount = get(
        action.data,
        'data.relationships.account',
      );

      if (!relationshipAccount) {
        return state;
      }

      return Account.createFromApi(relationshipAccount);
    }
    case 'LOCATION_SUCCESS': {
      const { city, country, countryCode } = action.data;

      return state
        ? state.setLocation({
            city,
            country,
            countryCode,
          })
        : state;
    }
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default account;
