import React, { useState } from 'react';

import { List } from 'immutable';

import { AccessVisibilityCommentIcon as CommentManagersIcon } from '@peakon/bedrock/icons/system';
import { UnstyledButton } from '@peakon/bedrock/react/button';
import { Employee } from '@peakon/records/index';
import { t } from '@peakon/shared/features/i18next/t';

import EmployeeSlideIn from '../../EmployeeSlideIn';

import styles from './styles.css';

type Props = {
  isLoading?: boolean;
  managers?: List<Employee>;
  onLoad: () => void;
};

export const CommentManagers = ({
  isLoading,
  managers = List(),
  onLoad,
}: Props) => {
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    onLoad();

    setOpen(true);
  };

  return (
    <div className={styles.root}>
      <UnstyledButton
        accessibleName={t('comment__managers')}
        onClick={handleOpen}
        onMouseEnter={() => {
          if (isLoading) {
            return;
          }

          onLoad();
        }}
      >
        <CommentManagersIcon />
      </UnstyledButton>
      {isOpen && (
        <EmployeeSlideIn
          isOpen={isOpen}
          isLoading={isLoading}
          handleOutsideClick={() => setOpen(false)}
          title={t('comment__managers')}
          employees={managers}
          totalEmployees={managers?.size}
        />
      )}
    </div>
  );
};
