import { Record, Set, Map, List } from 'immutable';
import { isValidNumber } from 'libphonenumber-js';
import isEqual from 'lodash/isEqual';
import { z } from 'zod';

import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { Attribute } from '.';
import { ValidFrom } from './AttributeRecord';
import Employee, { EMPLOYEE_KEYS, DEFAULT_FEATURES } from './EmployeeRecord';
import { validateTestingSchema } from './utils';

const employeeEditorSchema = z.object({});
const testingEmployeeEditorSchema = employeeEditorSchema.extend({
  employee: z.any(),
  attributes: z.any(),
});
type EmployeeEditorSchema = z.infer<typeof employeeEditorSchema>;

export class EmployeeEditor
  extends Record({
    employee: undefined,
    attributes: Map(),
  })
  implements EmployeeEditorSchema
{
  employee!: Employee;
  attributes!: Map<string, Attribute>;

  constructor(props: unknown = {}) {
    validateRecord(props, employeeEditorSchema, {
      errorMessagePrefix: 'EmployeeEditor',
    });
    validateTestingSchema(props, testingEmployeeEditorSchema, {
      errorMessagePrefix: 'EmployeeEditor',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  isValid() {
    return Boolean(
      typeof this.employee !== 'undefined' &&
        this.employee.firstName &&
        this.employee.lastName &&
        (this.employee.email || this.employee.identifier) &&
        this.attributes
          .filter((attr) => attr?.type === 'date')
          .every((attr) => Boolean(attr?.valid)) &&
        (this.employee.phone === null ||
          typeof this.employee.phone === 'undefined' ||
          isValidNumber(this.employee.phone)),
    );
  }
}

const editorSchema = z.object({
  isNew: z.boolean().optional(),
});
const testingEditorSchema = editorSchema.extend({
  original: z.any(),
  current: z.any(),
});
type EditorSchema = z.infer<typeof editorSchema>;

// eslint-disable-next-line import/no-default-export
export default class Editor
  extends Record({
    original: undefined,
    current: undefined,
    isNew: false,
    toggledFields: Set(),
  })
  implements EditorSchema
{
  original!: EmployeeEditor;
  current!: EmployeeEditor;
  isNew?: EditorSchema['isNew'];
  toggledFields?: Set<string>;

  constructor(props: unknown = {}) {
    validateRecord(props, editorSchema, {
      errorMessagePrefix: 'Editor',
    });
    validateTestingSchema(props, testingEditorSchema, {
      errorMessagePrefix: 'Editor',
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  diff() {
    const updatedAttributes = this.getIn(['current', 'attributes']).reduce(
      (modified: Map<string, Attribute>, current: Attribute) => {
        const original = this.getIn(['original', 'attributes', current.id]);

        return isEqual(current.getValue(), original.getValue())
          ? modified
          : modified.set(current.id, current);
      },
      Map(),
    );

    const updatedEmployee = Object.keys(EMPLOYEE_KEYS).reduce(
      (modified, key) => {
        const original = this.getIn(['original', 'employee', key]);
        const current = this.getIn(['current', 'employee', key]);

        if (key === 'attributes') {
          return modified;
        } else if (key === 'features') {
          const updatedFeatures = current.map(
            (value: boolean, featureKey: string) => {
              return original.get(featureKey) !== value ? value : undefined;
            },
          );

          // @ts-expect-error - Argument of type '"features"' is not assignable to parameter of type '"id"'
          return modified.set('features', updatedFeatures);
        }
        // @ts-expect-error - Argument of type 'string' is not assignable to parameter of type '"id"'
        return original !== current ? modified.set(key, current) : modified;
      },
      Map({ id: this.original.employee.id }),
    );

    return new EmployeeEditor({
      employee: new Employee(updatedEmployee),
      attributes: updatedAttributes,
    });
  }

  isEditing() {
    return (
      typeof this.current !== 'undefined' &&
      typeof this.original !== 'undefined'
    );
  }

  enable(id: string) {
    switch (id) {
      case 'engagement': {
        const current = this.getIn(['original', 'employee', 'features', id]);

        return typeof current === 'undefined'
          ? this.updateFeature(id, true)
          : this;
      }
      case 'locale':
      case 'timezone': {
        const current = this.getIn(['original', 'employee', id]);

        return typeof current === 'undefined'
          ? this.updateEmployee([id], undefined)
          : this;
      }
      default:
        return this;
    }
  }

  reset(id: string) {
    switch (id) {
      case 'locale':
      case 'timezone':
        return this.updateEmployee(
          [id],
          this.getIn(['original', 'employee', id]),
        );
      case 'engagement':
        return this.updateFeature(
          id,
          this.getIn(['original', 'employee', 'features', id]),
        );
      default: {
        const attribute = this.getIn(['original', 'attributes', id]);

        return attribute ? this.resetAttribute(attribute) : this;
      }
    }
  }

  updateEmployee(field: string[], value: string | undefined | null) {
    if (!this.isEditing()) {
      return this;
    }

    if (!this.hasIn(['current', 'employee', ...field])) {
      setTimeout(() => {
        throw new Error(
          `Trying to update employee invalid field ${
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            field
          }`,
        );
      });

      return this;
    }

    return this.setIn(['current', 'employee', ...field], value);
  }

  updateValidFrom(id: string, type: ValidFrom, date?: Date) {
    if (!this.isEditing()) {
      return this;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.updateIn(['current', 'attributes', id], (attribute) =>
      attribute.merge({
        validFrom: type,
        validFromDate: date,
      }),
    ) as Editor;
  }

  updateFeature(feature: string, value: boolean) {
    if (!this.isEditing()) {
      return this;
    }

    return this.setIn(['current', 'employee', 'features', feature], value);
  }

  addField(id: string) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.updateIn(['toggledFields'], (toggledFields) =>
      toggledFields.add(id),
    ) as Editor;
  }

  removeField(id: string) {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.updateIn(['toggledFields'], (toggledFields) =>
      toggledFields.remove(id),
    ) as Editor;
  }

  resetAttribute(original: Attribute) {
    if (!this.isEditing()) {
      return this;
    }

    return this.setIn(['current', 'attributes', original.id], original);
  }

  originalSetIn(keyPath: string[], value: string) {
    if (!this.isEditing()) {
      return this;
    }

    // eslint-disable-next-line no-param-reassign -- Automatically disabled here to enable no-param-reassign globally
    keyPath = Array.isArray(keyPath) ? keyPath : [keyPath];
    return this.setIn(['original', ...keyPath], value).setIn(
      ['current', ...keyPath],
      value,
    );
  }

  updateAttribute(
    id: string,
    value: string | number | Employee | undefined | null,
    translated?: string,
  ) {
    if (!this.isEditing()) {
      return this;
    }

    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    return this.updateIn(['current', 'attributes', id], (attribute) => {
      switch (attribute.type) {
        case 'number':
          return attribute.setIn(['value'], value);
        case 'date': {
          return attribute.merge({
            value,
            valid: true,
          });
        }
        case 'employee':
          if (value) {
            return attribute.merge({
              // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
              value: (value as Employee).get('id', null),
              options: [value],
            });
          }

          return attribute.setIn(['value'], null);
        case 'option': {
          return attribute.merge({
            selectedOption: value,
            selectedOptionTranslated: translated,
          });
        }
      }
    }) as Editor;
  }

  saveChanges(employee: Employee) {
    if (!this.current) {
      // if we enter here, it means the Editor has been unmounted
      // because the user navigated somewhere else.
      return this;
    }

    const editor = new EmployeeEditor({
      employee,
      attributes: this.current.attributes.map((attr) => attr?.reset()),
    });

    return this.merge({
      current: editor,
      original: editor,
    });
  }

  static createEmployee(
    attributes: Map<string, Attribute>,
    features = DEFAULT_FEATURES,
  ) {
    const editor = new EmployeeEditor({
      employee: new Employee({ features }),
      attributes: Map(
        attributes.map((attribute) => [attribute?.id, attribute]),
      ),
    });

    return new Editor({
      original: editor,
      current: editor,
      isNew: true,
    });
  }

  static editEmployee(employee: Employee, attributes: List<Attribute>) {
    const editor = new EmployeeEditor({
      employee,
      attributes: Map(
        attributes.map((attr) => [attr?.id, attr?.setFromEmployee(employee)]),
      ),
    });

    return new Editor({
      original: editor,
      current: editor,
      isNew: false,
    });
  }
}
