import z from 'zod';

import { httpErrorSchema } from '../isHttpError/isHttpError';

export const userErrorSchema = httpErrorSchema.and(
  z.object({
    id: z.string(),
    data: z.record(z.string(), z.unknown()),
  }),
);

type UserError = z.infer<typeof userErrorSchema>;

/**
 * Checks if the matches the shape of a UserError, a type of error thrown by our backend.
 *
 * It extends from HttpError (standard-http-error), and has an extra id and data property.
 *
 * See the implementation here: https://github.com/peakon/api/blob/master/libs/user_error.js
 *
 * @example
 * if(isUserError(error) && error.id === 'user_not_found') { ... }
 */
export const isUserError = (error: unknown): error is UserError => {
  return userErrorSchema.safeParse(error).success;
};
