import React from 'react';

import { useHistory } from 'react-router';

import { assignWindowLocation } from '@peakon/shared/utils/window';

import { useGetOpenSurveys } from './queries/useGetOpenSurveys';
import PageLoader from '../../../components/PageLoader';

export function SsoSurveyTransfer() {
  const history = useHistory();
  const { isLoading, data } = useGetOpenSurveys();

  if (!isLoading) {
    const hasOpenedSurveys = data?.length > 0;

    if (hasOpenedSurveys) {
      const searchParams = new URLSearchParams(history.location.search);
      searchParams.append('utm_source', 'sso');
      assignWindowLocation(
        `/transfer/employee/survey/${data[0].id}?${searchParams.toString()}`,
      );
    } else {
      assignWindowLocation('/surveys/no-surveys');
    }
  }

  return <PageLoader />;
}
