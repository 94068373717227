import { fromJS, Record } from 'immutable';
import { z } from 'zod';

import { translatedStringSchema } from '@peakon/shared/features/i18next/t';
import { validateRecord } from '@peakon/shared/utils/validateRecord/validateRecord';

import { ALL_QUESTION_SETS } from './constants/questionSets';
import { validateTestingSchema } from './utils';

const categoryGroupAccess = z.enum([
  'partial',
  'overview',
  'full',
  'questionSet',
]);

const categoryGroupStandardSchema = z.enum([
  ...ALL_QUESTION_SETS,
  'text',
  'open_ended', // FIXME: some places still use this old standard, we should convert them to 'text'
  'values',
]);

const schema = z.object({
  id: categoryGroupStandardSchema,
});
const testingSchema = schema.extend({
  access: categoryGroupAccess.nullable().optional(),
  categoryIds: z.array(z.string()).nullable(),
  hasOtherDrivers: z.boolean().optional(),
  hasOutcome: z.boolean().optional(),
  name: translatedStringSchema.optional(),
  nameTranslated: translatedStringSchema,
  questionsUpgradeDue: z.boolean().optional(),
  resource: z.array(z.enum(['score', 'comment'])).optional(),
  standard: categoryGroupStandardSchema,
});
type Schema = z.infer<typeof schema>;

class CategoryGroupRecord
  extends Record({
    id: undefined,
    access: undefined,
    categoryIds: null,
    hasOtherDrivers: false,
    hasOutcome: false,
    name: undefined,
    nameTranslated: undefined,
    questionsUpgradeDue: false,
    resource: null,
    standard: undefined,
  })
  implements Schema
{
  id!: Schema['id'];
  hasOtherDrivers!: boolean;
  nameTranslated!: string;
  questionsUpgradeDue!: boolean;
  resource: $TSFixMe;

  constructor(props: unknown = {}) {
    validateRecord(props, schema, {
      errorMessagePrefix: 'CategoryGroupRecord',
    });
    validateTestingSchema(props, testingSchema, {
      errorMessagePrefix: 'CategoryGroupRecord',
      log: [
        {
          environment: 'local',
          logLevel: 'error',
        },
        {
          environment: 'staging',
          logLevel: 'warning',
        },
        {
          environment: 'production',
          logLevel: 'warning',
        },
      ],
    });
    // @ts-expect-error - unknown is not assignable to record constructor
    super(props);
  }

  hasComments() {
    return this.resource.includes('comment');
  }

  static createFromApi(
    data: $TSFixMe,
    {
      keepStandard,
      access,
      hasOutcome,
      hasOtherDrivers,
      categories,
      resource,
    }: $TSFixMe = {},
  ) {
    let {
      attributes: { name, standard, nameTranslated, questionsUpgradeDue },
    } = data;

    if (!keepStandard) {
      standard = standard === 'text' ? 'open_ended' : standard;
    }

    return new CategoryGroupRecord(
      fromJS({
        id: standard,
        name,
        nameTranslated,
        standard,
        access,
        hasOtherDrivers,
        hasOutcome,
        resource,
        questionsUpgradeDue,
        categoryIds: categories
          ? categories.map(
              (
                // @ts-expect-error no implicit any
                category,
              ) => category.id,
            )
          : null,
      }),
    );
  }
}

// eslint-disable-next-line import/no-default-export
export default CategoryGroupRecord;
