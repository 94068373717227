import { z } from 'zod';

import {
  SENSITIVE_COMMENT_CONCEPT_NOTIFICATIONS,
  SENSITIVE_COMMENT_CONCEPT_TYPES,
  SENSITIVE_COMMENT_CONCEPT_STATUS,
} from './constants';

const sensitiveConceptAttributesSchema = z.object({
  type: z.enum(SENSITIVE_COMMENT_CONCEPT_TYPES).nullable(),
  status: z.enum(SENSITIVE_COMMENT_CONCEPT_STATUS),
  notifications: z.enum(SENSITIVE_COMMENT_CONCEPT_NOTIFICATIONS),
  name: z.string(),
  standard: z.boolean(),
});

const sensitiveKeywordAttributesSchema = z.object({
  keyword: z.string(),
  locale: z.string(),
});

export const sensitiveConceptSchema = z.object({
  type: z.literal('sensitive_concepts'),
  id: z.string(),
  attributes: sensitiveConceptAttributesSchema,
  relationships: z.object({
    keywords: z.array(
      z.object({
        type: z.literal('sensitive_keywords'),
        id: z.string(),
        attributes: sensitiveKeywordAttributesSchema,
        relationships: z.object({
          concept: z.object({
            type: z.string(),
            id: z.string(),
          }),
        }),
      }),
    ),
  }),
});

export const updatedConceptSchema = z.object({
  type: z.literal('sensitive_concepts'),
  id: z.string(),
  attributes: sensitiveConceptAttributesSchema,
  relationships: z.object({
    keywords: z.object({
      data: z.array(
        z.object({
          type: z.literal('sensitive_keywords'),
          id: z.string(),
        }),
      ),
    }),
  }),
});

export const sensitiveKeywordSchema = z.object({
  type: z.literal('sensitive_keywords'),
  id: z.string(),
  attributes: sensitiveKeywordAttributesSchema,
  relationships: z.object({
    concept: z.object({
      data: z.object({
        type: z.literal('sensitive_concepts'),
        id: z.string(),
      }),
    }),
  }),
});

export type SensitiveConcept = z.infer<typeof sensitiveConceptSchema>;
