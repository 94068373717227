import React from 'react';

import { Stack } from '@peakon/bedrock/react/layout';
import { Heading3, Paragraph } from '@peakon/bedrock/react/typography';
import { t } from '@peakon/shared/features/i18next/t';

import styles from './styles.css';

export function ExpiredState() {
  return (
    <div className={styles.emptyState}>
      <Stack spacing={4}>
        <Heading3 level={1}>
          {t('team_suggestions__standalone__card__expired')}
        </Heading3>
        <Paragraph>{t('team_suggestions__standalone__card__unset')}</Paragraph>
      </Stack>
    </div>
  );
}
