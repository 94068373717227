import merge from 'lodash/merge';
import z from 'zod';

import { ensureJavascriptObject } from '@peakon/records/utils';

import { reportZodError } from '../reportZodError';

/** Wrapper around `validateData` but calls `ensureJavascriptObject` on the input and ignores Record-specific issues in our tests */
export const validateRecord = <T extends z.ZodTypeAny>(
  data: unknown,
  schema: T,
  {
    defaultValues,
    errorMessagePrefix,
    strictTypesInTest,
  }: {
    defaultValues?: Record<string, unknown>;
    errorMessagePrefix: string;
    /** Don't filter out specific problems in tests.
     *
     * Set this to true if you want to fix our tests */
    strictTypesInTest?: boolean;
  },
) => {
  const normalizedDefaultValues = ensureJavascriptObject(defaultValues);
  const normalized = ensureJavascriptObject(data);
  const result = schema.safeParse(merge(normalizedDefaultValues, normalized));
  if (result.success) {
    return;
  }

  // in our using tests we don't respect our types, so let's ignore "required" and "invalid type" for `id` errors
  if (!strictTypesInTest && ENV.clusterEnv === 'test') {
    result.error.issues = result.error.issues
      .filter((issue) => issue.message !== 'Required')
      .filter(
        (issue) =>
          // filter out passing invalid types for `id`
          !(
            issue.path.length === 1 &&
            (issue.path[0] === 'id' || String(issue.path[0]).endsWith('Id')) &&
            [
              'Expected string, received number',
              'Expected number, received string',
            ].includes(issue.message)
          ),
      );
    if (result.error.issues.length === 0) {
      return;
    }
  }

  reportZodError({
    error: result.error,
    errorMessagePrefix,
    data: normalized,
  });
};
